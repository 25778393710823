
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class LimeLogo extends Vue {
  @Prop({default: '187'}) width?: string | number;
  @Prop({default: '28'}) height?: string | number;
  @Prop({default: 'default'}) color?: string;

  get isGray(): boolean {
    return this.color === 'gray';
  }

  get fillText(): string {
    if (this.isGray) return '#ccc';
    if (this.color === 'default' || !this.color) return '#1E2247';
    return '#1E2247';
  }
}
