import { render, staticRenderFns } from "./ProjectViewQuota.vue?vue&type=template&id=e8719ada&scoped=true"
import script from "./ProjectViewQuota.vue?vue&type=script&lang=ts"
export * from "./ProjectViewQuota.vue?vue&type=script&lang=ts"
import style0 from "./ProjectViewQuota.vue?vue&type=style&index=0&id=e8719ada&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8719ada",
  null
  
)

export default component.exports