import Vue from "vue";
import Vuex from "vuex";
import { IUserSessionInfo } from "@/interface/user/user";
import { ADMIN_ID, ROLE } from "@/types/user";
import { ins as axios } from "@/utils/axios";
import socketStore from "@/store/modules/socket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: Object.prototype.hasOwnProperty.call(sessionStorage, "token"),
    token: sessionStorage.token || "",
    role: sessionStorage.role || "",
    autoLogin: false,
    userId: sessionStorage.userId || "",
    listMode: "card",
    userName: sessionStorage.userName || "",
    userNickname: sessionStorage.userNickname || "",
    accessToken: "",
  },
  getters: {
    userNickname: (state: IUserSessionInfo) => {
      state.userNickname = sessionStorage.userNickname || "";
      return state.userNickname;
    },
    isLogin: (state: IUserSessionInfo) => {
      state.isLogin = Object.prototype.hasOwnProperty.call(
        sessionStorage,
        "token"
      );
      return state.isLogin;
    },
    token: (state: IUserSessionInfo) => {
      state.token = sessionStorage.token || "";
      return state.token;
    },
    accessToken: (state: IUserSessionInfo) => {
      return state.accessToken;
    },
    autoLogin: (state: IUserSessionInfo) => {
      return state.autoLogin;
    },
    userId: (state: IUserSessionInfo) => {
      state.userId = sessionStorage.userId || "";
      return state.userId;
    },
    listMode: (state: IUserSessionInfo) => {
      return state.listMode;
    },
    userName: (state: IUserSessionInfo) => {
      state.userName = sessionStorage.userName || "";
      return state.userName;
    },
    userRole: (state: IUserSessionInfo) => {
      state.role = sessionStorage.role || "";
      return state.role;
    },
    isAdmin: (state: IUserSessionInfo) => {
      const { role, userId } = state;
      return role === ROLE.ADMIN || userId === ADMIN_ID;
    },
  },
  mutations: {
    logOut(state: IUserSessionInfo) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("userNickname");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("role");
      state.token = sessionStorage.token || "";
      state.userNickname = sessionStorage.userNickname || "";
      state.userName = sessionStorage.userName || "";
      state.userId = sessionStorage.userId || "";
      state.role = sessionStorage.role || "";
      state.isLogin = Object.prototype.hasOwnProperty.call(
        sessionStorage,
        "token"
      );
    },
    login(state: IUserSessionInfo, { data }) {
      sessionStorage.token = data.token || "";
      sessionStorage.userName = data.NAME || "";
      sessionStorage.userId = data._id || "";
      sessionStorage.userNickname = data.NICKNAME || "";
      sessionStorage.role = data.ROLE || "";
      state.token = sessionStorage.token;
      state.userName = sessionStorage.userName || "";
      state.userNickname = sessionStorage.userNickname || "";
      state.userId = sessionStorage.userId || "";
      state.role = sessionStorage.role || "";
      state.isLogin = Object.prototype.hasOwnProperty.call(
        sessionStorage,
        "token"
      );
    },
    listMode(state: IUserSessionInfo, { listMode }) {
      state.listMode = listMode;
    },
    changeNickname(state: IUserSessionInfo, newNickname) {
      state.userNickname = newNickname;
    },
    setAccessMfa(state: IUserSessionInfo, accessToken) {
      state.accessToken = accessToken;
    },
  },
  actions: {
    async login(context, { data }) {
      await context.commit("login", { data });
    },
    async logOut(context) {
      context.commit("logOut");
    },
    async verify(context) {
      try {
        const { data } = await axios.get(`/admin/user/verify`);
        if (data) {
          const { role, token, userId, userName, userNickname } = data;
          const payload = {
            _id: userId,
            NAME: userName,
            NICKNAME: userNickname,
            token: token,
            ROLE: role,
          };
          await context.commit("login", { data: payload });
        }
        return {
          result:
            Object.prototype.hasOwnProperty.call(data, "token") &&
            data.token !== "" &&
            data.token !== undefined &&
            data.token !== null,
        };
      } catch (e) {
        console.log(e);
      }
    },
    async projectAccess(context, payload) {
      try {
        return axios.get(`/project/access/${payload}`);
      } catch (e) {
        console.log(e);
      }
    },
    listMode(context, { listMode }) {
      context.commit("listMode", { listMode });
    },
  },
  modules: {
    socketStore,
  },
});
