
import { Vue, Component, Ref } from "vue-property-decorator";
import IdIcon from "@/components/icons/login/IdIcon.vue";
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import { IUserLogin } from "@/interface/user/user";
import { USER } from "@/types/user";

@Component({
  components: {
    IdIcon,
    PasswordIcon,
  },
})
export default class LoginComponent extends Vue {
  @Ref() passwordInput: HTMLElement;

  idFocus = false;
  passwordFocus = false;
  idActive = "id_focus";
  passwordActive = "password_focus";
  idFocusInput = "id_focus_input";
  pwFocusInput = "password_focus_input";

  loginInfo: IUserLogin;
  failCount: number;
  failFlag: string;
  isLoading = false;

  passwordConfirm = false;
  code: number | null = null;
  expiresMinutes = 4;
  expiresSeconds = 60;
  expires = 300;
  expiresInterval: number;

  constructor() {
    super();
    this.loginInfo = USER.LoginInit();
    this.failCount = 0;
    this.failFlag = "";
  }

  get timerLabel() {
    const isStart =
      Math.ceil(this.expires / 60) - 1 === this.expiresMinutes &&
      this.expiresSeconds === 60;
    return `0${isStart ? 5 : this.expiresMinutes}:${
      this.expiresSeconds < 10
        ? `0${this.expiresSeconds}`
        : isStart
        ? "00"
        : this.expiresSeconds
    }`;
  }

  async login(): Promise<void> {
    if (!this.idValidation()) return;
    if (!this.pwdValidation()) return;

    const sendData = this.createSendData();

    const { data } = await this.axios.post(`/admin/user/login`, sendData);
    const { result, token } = data;
    if (result) {
      this.passwordConfirm = result;
      this.$store.commit("setAccessMfa", token);
      this.onTimer();
    } else {
      this.loginInfo.userPwd = "";
      this.failFlag = data.failFlag || "";
      if (this.failFlag == "auth") {
        this.$toast.error(data.message || "error!");
      }
    }
  }

  async mfaLogin(): Promise<void> {
    if (!this.code) {
      this.$toast.error("인증번호를 입력하세요.");
      return;
    }
    const { data } = await this.axios.post(`/admin/user/mfa-login`, {
      code: this.code,
    });
    const { result } = data;
    if (result) {
      this.$store.commit("setAccessMfa", "");
      await this.$store.dispatch("login", { data });
      await this.$router.push("/admin/list");
    }
  }

  async recreateCode() {
    if (!this.idValidation()) return;
    if (!this.pwdValidation()) return;

    const sendData = this.createSendData();
    const { data } = await this.axios.post(`/admin/user/login`, sendData);
    const { result } = data;
    if (result) {
      this.$toast.success("인증번호가 재전송되었습니다.");
      this.expiresMinutes = 4;
      this.expiresSeconds = 60;
      this.expires = 300;
      this.onTimer();
    } else {
      this.$toast.error(
        "인증번호 발송에 실패하였습니다. 관리자에게 문의하세요."
      );
    }
  }

  expiresTimer() {
    this.expiresSeconds--;
    this.expires--;
    if (this.expires === 0) {
      this.expiresMinutes = 0;
      this.expiresSeconds = 0;
      this.clearTimer();
    }
    if (this.expiresSeconds < 0) {
      this.expiresMinutes--;
      this.expiresSeconds = 59;
    }
  }

  onTimer() {
    this.expiresInterval = setInterval(this.expiresTimer, 1000);
  }

  clearTimer() {
    clearInterval(this.expiresInterval);
  }

  back() {
    this.loginInfo.userPwd = "";
    this.code = null;
    this.expiresMinutes = 4;
    this.expiresSeconds = 60;
    this.expires = 300;
    this.clearTimer();
    this.passwordConfirm = false;
  }

  changeCode(e: any) {
    let v = e.target.value.replace(/[^0-9]/g, "");
    if (6 <= v.length) v = v.slice(0, 6);
    e.target.value = v;
    this.code = +v;
  }

  idValidation(): boolean {
    const isIdNull = !this.loginInfo.userId;
    if (isIdNull) {
      this.$toast.error("아이디를 입력해 주세요.");
      return false;
    }
    return true;
  }

  pwdValidation(): boolean {
    const isPwNull = !this.loginInfo.userPwd;
    if (isPwNull) {
      this.$toast.error("비밀번호를 입력해 주세요.");
      return false;
    }
    return true;
  }

  createSendData(): IUserLogin {
    const { userId, userPwd } = this.loginInfo;
    return {
      userId,
      userPwd,
    };
  }

  get failState(): boolean {
    return this.failCount >= 5;
  }
}
