<template>
  <div id="app">
    <top-nav v-if="!excludeTop" />
    <router-view class="mobile-hide" :key="$route.fullPath" />
  </div>
</template>

<style></style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");
#app {
  font-family: "NanumSquare", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f9f9f9;
  height: 100%;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import TopNav from "@/components/TopNav";
export default {
  components: { TopNav },
  created() {
    this.$store.dispatch("socketStore/setSocket", {
      userId: this.userId,
      token: this.token,
    });
  },
  beforeDestroy() {
    this.$store.dispatch("socketStore/socketDestroy");
  },
  computed: {
    excludeTop() {
      return ["login"].includes(this.$route.name);
    },
  },
};
</script>
