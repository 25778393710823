
import { Component, Prop, Vue } from "vue-property-decorator";
import { IHelp, HELP_CATEGORY } from "@/types/board";

@Component({})
export default class NoticeTable extends Vue {
  @Prop() tabIndex?: number;
  tabKey = "";
  loading = true;
  helpList: IHelp[] = [];
  page = 1;
  totalRow = 0;
  limitPage = 10;
  search = "";
  searchButtonHover = true;

  async mounted() {
    await this.load();
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  async load() {
    try {
      this.tabKey = this.tabIndex === 0 ? "공지사항" : "도움말";
      const sendData = {
        limit: this.limitPage,
        page: this.page,
        category: this.tabKey,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, {
        params: sendData,
      });
      const { result, count, list } = data;
      if (result == true) {
        this.helpList = list;
        this.totalRow = count;
      }
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  get helpListLength(): number {
    return this.helpList.length;
  }

  categoryVariant(category: HELP_CATEGORY): string {
    if (category === HELP_CATEGORY.NOTICE) return "warning";
    else if (category === HELP_CATEGORY.USE) return "success";
    else return "primary";
  }

  helpView(index: number) {
    const list: IHelp = this.helpList[index];
    const { _id } = list;
    this.$router.push({
      name: "qna-read",
      params: { helpId: _id },
      query: { tabIndex: this.tabIndex + "" },
    });
    //this.$router.push(`/admin/qna-read/${this.tabKey ==='공지사항'? 'notice' : 'help'}/${_id}`);
  }
}
