
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HelpList extends Vue {
  @Prop() loading;
  @Prop() helpList;

  statusColor(status: string): string {
    if (status === "확인중") return "warning";
    if (status === "답변완료") return "success";
    return "info";
  }

  questionView(index: number) {
    this.$emit("view", index);
  }
}
