
import { Vue, Component } from "vue-property-decorator";
import HelpCard from "@/components/login/HelpCard.vue";
import LimeLogo from "@/components/icons/LimeLogo.vue";
import { loginServiceGuideData } from "@/assets/data/loginServiceGuideData";

@Component({
  components: {
    HelpCard,
    LimeLogo,
  },
})
export default class LoginBanner extends Vue {
  get helpData() {
    return loginServiceGuideData;
  }
}
