
import {Component, Prop, Vue} from 'vue-property-decorator';
import * as BOARD from "@/types/board";
import BoardDeleteModal from '@/components/modal/BoardDeleteModal.vue';

@Component({
  components: {
    BoardDeleteModal,
  },
})
export default class QuestionView extends Vue {
  @Prop() boardQuestion;
  @Prop() boardQuestionList;
  @Prop() lastReply;
  @Prop() userObjectId;

  statusColor(status: string): string {
    if ( status === BOARD.QUESTION_STATUS.CHECKING ) return 'warning';
    if ( status === BOARD.QUESTION_STATUS.COMPLETE ) return 'success';
    else return '';
  }

  toList() {
    this.$emit('toList');
  }

  toReply(level) {
    this.$emit('toReply', level);
  }

  remove() {
    this.$emit('remove');
  }
}
