
import { Component, Vue } from "vue-property-decorator";
import ProjectListSkeleton from "@/components/board/ProjectListSkeleton.vue";
import moment from "moment";
import { Dictionary } from "vue-router/types/router";
import { QUESTION } from "@/types/question";
import SearchIcon from "@/components/icons/common/SearchIcon.vue";

interface OWNER {
  OWNER: string[];
}

interface IProjectList {
  ROLE: OWNER;
  CONFIG: any;
  SNUM: number;
  TITLE: string;
  isUse: boolean;
  regDate: Date;
  _id: string;
  STATUS: string;
}

@Component({
  components: {
    SearchIcon,
    ProjectListSkeleton,
  },
})
export default class ProjectList extends Vue {
  page = 1;
  numberOfPages = 1;
  limitPage = 10;
  search = "";
  searchType = "TITLE";
  searchDateStart = "";
  searchDateEnd = "";
  loading = false;
  searchButtonHover = false;

  searchOptions = [
    { value: "TITLE", text: "프로젝트명" },
    { value: "snum", text: "UNIID" },
    { value: "LINK_STATUS", text: "진행상태" },
  ];

  statusOptions = [
    { value: QUESTION.LINK_STATUS.DEFAULT, text: "링크생성전" },
    { value: QUESTION.LINK_STATUS.TEST, text: "테스트 링크 생성" },
    { value: QUESTION.LINK_STATUS.REAL, text: "링크 확정" },
    /*{ value: QUESTION.LINK_STATUS.PAID , text: '결제완료' },*/
    { value: QUESTION.LINK_STATUS.REVIEW_HOLD, text: "검수 대기" },
    { value: QUESTION.LINK_STATUS.REVIEW, text: "검수중" },
    { value: QUESTION.LINK_STATUS.REVIEW_DONE, text: "검수 완료" },
    { value: QUESTION.LINK_STATUS.REJECT, text: "반려" },
    { value: QUESTION.LINK_STATUS.REFUND, text: "환불" },
    { value: QUESTION.LINK_STATUS.FW_START, text: "진행중" },
    { value: QUESTION.LINK_STATUS.FW_END, text: "진행완료" },
    { value: QUESTION.LINK_STATUS.FW_PAUSE, text: "중단" },
    { value: QUESTION.LINK_STATUS.FW_CANCEL, text: "취소" },
    { value: QUESTION.LINK_STATUS.FW_SHUT_DOWN, text: "종료" },
  ];

  limitOptions = [
    { value: 10, text: "10개씩 보기" },
    { value: 20, text: "20개씩 보기" },
    { value: 30, text: "30개씩 보기" },
    { value: 40, text: "40개씩 보기" },
    { value: 50, text: "50개씩 보기" },
  ];
  questionList: IProjectList[] = [];

  async created() {
    this.init();
    await this.load();
  }

  init() {
    const {
      page,
      search,
      searchType,
      searchDateStart,
      searchDateEnd,
      limitPage,
    } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (searchDateStart) this.searchDateStart = searchDateStart;
    if (searchDateEnd) this.searchDateEnd = searchDateEnd;
    if (limitPage) this.limitPage = +limitPage;
  }

  async load(): Promise<void> {
    this.loading = true;

    try {
      const params = {
        pageNum: this.page,
        perPage: this.limitPage,
        searchType: this.searchType,
        search: this.search,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      };
      const { data } = await this.axios.get("/admin/project", {
        params,
      });
      const { result, list, numberOfPages } = data;

      if (result) {
        this.questionList = list.map((r) => {
          const { CONFIG, regDate } = r;
          const { LINK_STATUS } = CONFIG;

          let STATUS = "";
          switch (LINK_STATUS) {
            case -1:
              STATUS = "링크 생성 전";
              break;
            case 0:
              STATUS = "테스트링크생성";
              break;
            case 1:
              STATUS = "링크확정";
              break;
            case 2:
              STATUS = "결제완료";
              break;
            case 201:
              STATUS = "검수대기";
              break;
            case 202:
              STATUS = "검수중";
              break;
            case 203:
              STATUS = "검수완료";
              break;
            case 3:
              STATUS = "반려";
              break;
            case 4:
              STATUS = "환불";
              break;
            case 101:
              STATUS = "진행중";
              break;
            case 102:
              STATUS = "진행완료";
              break;
            case 103:
              STATUS = "중단";
              break;
            case 104:
              STATUS = "취소";
              break;
            case 105:
              STATUS = "강제종료";
              break;
          }

          return {
            ...r,
            STATUS,
            regDate: this.dateFormat(new Date(regDate)),
          };
        });
        this.numberOfPages = numberOfPages || 1;
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp)
      .map((entry) => entry.join("="))
      .join("&");

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue,
    });
    this.$router.push(query).catch(() => ({}));
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    });
  }

  isSelected(snum: number) {
    const { id } = this.$route.params;
    return !isNaN(+id) ? +id === snum : false;
  }

  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }

  redirectProjectDetail(SNUM: number) {
    this.$router.push({
      path: `/admin/list/${SNUM}`,
      query: this.$route.query,
    });
  }

  dateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  async xlsxDownload() {
    this.loading = true;
    try {
      const params = {
        pageNum: this.page,
        perPage: this.limitPage,
        searchType: this.searchType,
        search: this.search,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      };

      const data = await this.axios({
        url: "/admin/project/xlsx",
        method: "GET",
        params: params,
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "프로젝트 리스트.xlsx");
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
