
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class HelpCard extends Vue {
  @Prop() content: string;
  @Prop() thumbnails: string;
  @Prop() thumbnailsAlign: string;
  @Prop() title: string;

  images = ["1", "2", "4", "5"];
}
